



















































































































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component, Ref, Watch} from "vue-property-decorator";
import {
  DataDictionaryDtoPagedResultDto,
  FundDto,
  FundDtoPagedResultDto,
  FundIncomeRecordDto,
  FundIncomeRecordTotalDto,
  FundProjectDto,
  FundProjectDtoPagedResultDto,
  FundProjectType
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import PagedTabsTableView from "@/components/PagedTableView/PagedTabsTableView.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";

@Component({
  name: "FundIncomeRecordList",
  components: {
    PagedTabsTableView,
    PagedTableView,
    AbSelect,
    ExportButton,
    ImportExcel
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    }
  }
})
export default class FundIncomeRecordList extends Vue {
  @Ref() readonly pagedTableView!: any;
  typeList: any = [];
  fundList: FundDto[] = [];
  fundProjectList: FundProjectDto[] = [];
  donorType: any = [];
  incomeType: any = [];
  payList: any = [];
  yesOrNoList = [
    {
      key: "月捐",
      value: true
    },
    {
      key: "单次",
      value: false
    }
  ];

  queryForm = {
    incomeType: "",
    fundId: undefined,
    projectId: undefined
  };

  fundId = 0;
  fundName = "";
  projectId = 0;
  projectName = "";
  detailId = 0;
  isShow = false

  totalMoneyStatistics: FundIncomeRecordTotalDto = {};

  @Watch("$route.params")
  changeRoute(newVal: any) {
    this.pagedTableView!.fetchData();
  }

  created() {
    if (this.$route.query.fundId) {
      this.fundId = Number(this.$route.query.fundId);
      this.queryForm.fundId = this.fundId as any;
      api.fund.get({id: this.queryForm.fundId}).then((fund) => {
        this.fundName = fund!.name ?? "";
      });
    }
    if (this.$route.query.projectId) {
      this.projectId = Number(this.$route.query.projectId);
      this.queryForm.projectId = this.projectId as any;
      api.fundProject.get({id: this.queryForm.projectId}).then((res) => {
        this.projectName = res!.name ?? "";
      });
    }
    this.fetchEnumType();
    this.fetchFundList();
    this.fetchFundProjectList();
    this.fetchDataDictionary();
  }

  async fetchFundList() {
    await api.fund
      .getAll({
        maxResultCount: 10000
      })
      .then((res: FundDtoPagedResultDto) => {
        this.fundList.push({id: 0, name: ""});
        this.fundList = Object.assign(this.fundList, res.items ?? []);
      });
  }

  async fetchFundProjectList() {
    await api.fundProject
      .getAll({
        projectType: FundProjectType.OnlineFundraising,
        maxResultCount: 10000
      })
      .then((res: FundProjectDtoPagedResultDto) => {
        this.fundProjectList = res.items ?? [];
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({key: "PaymentType", maxResultCount: 1000})
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.payList = res.items;
      });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType"
      })
      .then((res) => {
        this.typeList = res;
      });
    await api.enumService
      .getValues({
        typeName: "DonorType"
      })
      .then((res) => {
        this.donorType = res;
      });
    await api.enumService
      .getValues({
        typeName: "IncomeType"
      })
      .then((res) => {
        this.incomeType = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    api.fundIncomeRecord
      .getTotalMoney(params)
      .then((res: FundIncomeRecordTotalDto) => {
        this.totalMoneyStatistics = res;
      });

    return api.fundIncomeRecord.getAll(params);
  }

  // 查看详情
  jumpDetail(index: number, row: FundIncomeRecordDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "fundIncomeRecordDetail",
      params: {id: row.id!.toString()}
    });
  }

  isShowCertificate() {
    let tenantId = JSON.parse(window.sessionStorage.getItem("tenant") as any).tenantId;
    if (tenantId === 52) {
      this.isShow = true;
    }
  }

  //展示证书
  showCertificate(index: number, row: FundIncomeRecordDto) {
    // console.log(index, row);
    let tenantId = JSON.parse(window.sessionStorage.getItem("tenant") as any).tenantId;
    // console.log('tenantId',tenantId);
    if (tenantId === 52) {
      this.$router.push({
        name: 'donationCertificate',
        params: {id: row.id!.toString()}
      })
    }
  }

  handleCreate(index: number, row: FundIncomeRecordDto) {
    this.$router.push({
      name: "fundIncomeRecordCreate"
    });
  }

  handleEdit(index: number, row: FundIncomeRecordDto) {
    this.$router.push({
      name: "fundIncomeRecordEdit",
      params: {id: row.id!.toString()}
    });
  }

  handleDelete(index: number, row: FundIncomeRecordDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.fundIncomeRecord.delete({id: row.id}).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  private cancel() {
    this.$router.back();
  }

  get pageHeadContent() {
    if (this.fundName) {
      return `基金【${this.fundName}】拨付记录`;
    } else if (this.projectName) {
      return `项目【${this.projectName}】拨付记录`;
    } else {
      return "Loading...";
    }
  }
}
