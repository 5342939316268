





































































import PagedTableView from "./index.vue";
import { Component } from "vue-property-decorator";

@Component({
  name: "PagedTabsTableView",
})
export default class PagedTabsTableView extends PagedTableView {
  activeTab = "table";
}
